import { useNotificationStore } from './notification.store';
import { type Notification } from './notification.interface';

interface PluginsInjections {
    $notification(notification: Partial<Notification>): void;
}

declare module '#app' {
    interface NuxtApp extends PluginsInjections {}
}

declare module 'nuxt/dist/app/nuxt' {
    interface NuxtApp extends PluginsInjections {}
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties extends PluginsInjections {}
}

export default defineNuxtPlugin(() => {
    const { setNotification } = useNotificationStore();
    return {
        provide: {
            notification: setNotification
        }
    };
});
