import { type Direction } from './interfaces';

export type Callback<CallbackParameters> = (event: CallbackParameters) => void;

export enum Types {
    KEY_DOWN = 'keydown',
    BACK_BUTTON = 'backbutton'
}

export type Type = `${Types}`;

export interface CallbackParameters {
    keyboardEvent?: KeyboardEvent;
    direction?: Direction;
}

export class LrudEmitter {
    private callbacks: Callback<CallbackParameters>[] = [];
    private stopImmediatePropagation: boolean = false;

    // Метод для подписки на событие
    on(callback: Callback<CallbackParameters>) {
        this.callbacks.push(callback);
    }

    // Метод для отписки от события
    off(callback: Callback<CallbackParameters>) {
        const index = this.callbacks.indexOf(callback);
        if (index !== -1) {
            this.callbacks.splice(index, 1);
        }
    }

    // Метод для вызова всех подписанных колбэков и определения, следует ли предотвратить действие по умолчанию
    emit(event: CallbackParameters) {
        // Клонируем список событий и запускаем с последнего
        const callbackReverse = [...this.callbacks];
        callbackReverse.reverse();

        // Если в процессе события произошел stop, запрещаем дальнейшее действие.
        for (const callback of callbackReverse) {
            if (!this.stopImmediatePropagation) {
                callback(event);
            } else {
                break;
            }
        }

        // Сбрасываем стоп, до следующего запуска
        this.stopImmediatePropagation = false;
    }

    stop(): void {
        this.stopImmediatePropagation = true;
    }
}
