import { NotificationListItemAppearance, NotificationListItemType } from '../notification.enum';

export declare type NotificationItemType = `${NotificationListItemType}`;

// custom plugin $notification
export default (msg: string, level?: NotificationItemType, options?: Object) => {
    const nuxtApp = useNuxtApp();

    nuxtApp.$notification({
        message: msg,
        type: (level as NotificationListItemType) || NotificationListItemType.Info,
        // TODO: Перенести в options модуля
        showIcon: true,
        dismiss: {
            manually: true,
            automatically: true
        },
        duration: 5000,
        showDurationProgress: true,
        appearance: NotificationListItemAppearance.Glass,
        ...options
    });
};
