// useFetchProxyApi(url, opts)
// Обертка над useFetchApi с возможностью отправлять объекты в query и базовым url API

// Импорт функции useFetchApi из другого файла
import type { UseFetchRequest, UseFetchOptionsCustom } from './useFetchApi';
import useFetchApi from './useFetchApi';

export default function <Type, Key>(request: UseFetchRequest, opts?: UseFetchOptionsCustom<Type, Key>) {
    // Получаем конфигурацию из файла nuxt.config.ts
    const config = useRuntimeConfig();
    const protocol = 'https:';

    // Если параметры opts не переданы, создаем пустой объект
    if (!opts) {
        opts = {};
    }

    // Если заголовки не указаны, создаем пустой объект
    if (!opts?.headers) {
        opts.headers = {};
    }

    if (!opts?.token) {
        const authStore = useAuth();
        opts.token = authStore.isAuth && authStore?.accessToken ? authStore.accessToken : undefined;
    }

    // Проксируем базовый URL
    opts.baseURL = protocol + '//' + config.public.apiProxy; // <<<==== CONFIG (nuxt.config.ts) PARAM PROXY URL

    // Используем функцию useFetchApi для отправки запроса
    return useFetchApi(request, opts);
}
