export type ItemUrl = {
    type?: 'container' | 'item';
    id?: number | string;
    container?: any;
    item?: any;
};

/**
 * Генерирует URL на основе предоставленных параметров.
 *
 * @param item - Объект элемента.
 * @param type - Тип элемента.
 * @param compilation - Идентификатор компиляции.
 * @returns Сгенерированный URL.
 */
export default (item: ItemUrl, type?: string, compilation?: string) => {
    // Деструктурируем item, если он предоставлен с типом
    if (item?.type && item?.item) {
        ({ type, item } = item);
    }

    // Если это элемент контейнера
    if ((!type || type === 'item') && item?.container?.id) {
        const rootContainerId = item.container?.parentId || item.container.id;
        type = 'container';
        item = {
            id: rootContainerId
        };
    }

    const itemId = item?.id;
    switch (type) {
        case 'channel': {
            const initialPath = '/live';
            const params = new URLSearchParams();

            if (compilation) {
                params.set('compilation', compilation);
            }

            if (itemId) {
                params.set('channel', String(itemId));
            }

            const queryString = params.toString();
            return queryString ? `${initialPath}?${queryString}` : initialPath;
        }
        case 'item':
            return `/item/${itemId}/detail`;
        case 'item-view':
            return `/item/${itemId}`;
        case 'container':
            return `/${type}/${itemId}`;
        default:
            return '#';
    }
};
