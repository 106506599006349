import SvgIconMonitor from '~/assets/sprite/monitor.svg';
import SvgIconSearch from '~/assets/sprite/search.svg';
import SvgIconYoutube from '~/assets/sprite/youtube.svg';
import SvgIconRadio from '~/assets/sprite/radio.svg';
import SvgIconHeart from '~/assets/sprite/heart.svg';
// import SvgIconLayers from '~/assets/sprite/layers.svg';
import SvgIconFilm from '~/assets/sprite/film.svg';
// import SvgIconUser from '~/assets/sprite/user.svg';
import SvgIconArchive from '~/assets/sprite/archive.svg';
import SvgIconBack from '~/assets/sprite/backpage.svg';

export interface NavigationItem {
    title: string | object;
    link: string;
    icon: string;
    childCheck: boolean;
    event?: () => void;
}

export const navigationItems: NavigationItem[] = [
    {
        title: 'Поиск',
        link: '/search',
        icon: SvgIconSearch,
        childCheck: false
    },
    {
        title: 'Главная',
        link: '/',
        icon: SvgIconMonitor,
        childCheck: false
    },
    {
        title: 'Продолжить просмотр',
        link: '/browsing',
        icon: SvgIconYoutube,
        childCheck: false
    },
    {
        title: 'Прямой эфир',
        link: '/live',
        icon: SvgIconRadio,
        childCheck: true
    },
    {
        title: 'Избранное',
        link: '/favorite',
        icon: SvgIconHeart,
        childCheck: false
    },
    {
        title: 'Кинотеатр / VOD',
        link: '/cinema',
        icon: SvgIconFilm,
        childCheck: false
    },
    {
        title: 'История просмотров',
        link: '/history',
        icon: SvgIconArchive,
        childCheck: true
    },
    {
        title: 'Вернуться к yes+',
        link: '#',
        icon: SvgIconBack,
        childCheck: false,
        // @ts-ignore
        event: () => window.application?.close() || useLogger('NOT_FOUND_CLOSE_APP_ACTION')
    }
];
