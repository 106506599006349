import useAuth from '~/composables/useAuth';

export default defineNuxtRouteMiddleware((to, from) => {
    const { token } = from.query;
    const { path } = to;

    // На сервере и на странице /auth проверка не нужна
    if (!process.client || path === '/auth') {
        return;
    }

    const storeAuth = useAuth();
    if (token) {
        storeAuth.setToken(token);
    }

    // Если токена нет
    if (!storeAuth.isAuth.value) {
        return storeAuth.reset();
    }

    // Проверяем токен на каждом хите
    if (storeAuth.isAuth.value) {
        return storeAuth.check();
    }
});
