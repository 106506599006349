import { defineComponent, h } from 'vue';
import { useLoadingIndicator } from '#app/composables/loading-indicator';
import Loader from './Loader.vue';

export default defineComponent({
    name: 'NuxtLoadingIndicator',
    props: {
        throttle: {
            type: Number,
            default: 200
        },
        duration: {
            type: Number,
            default: 2000
        }
    },
    setup(props, { expose }) {
        const { progress, isLoading, start, finish, clear } = useLoadingIndicator({
            duration: props.duration,
            throttle: props.throttle
        });

        expose({
            progress,
            isLoading,
            start,
            finish,
            clear
        });

        return () => {
            if (isLoading.value) {
                return h(Loader);
            }
        };
    }
});
