<script setup lang="ts">
import { computed } from 'vue';
import { type Notification } from '../notification.interface';
import { NotificationListPosition, NotificationListItemLayout } from '../notification.enum';
import { useNotificationStore } from '../notification.store';
import VueNotificationListItem from './VueNotificationListItem.vue';

// Define props for the component
const props = defineProps({
    position: {
        type: String as PropType<NotificationListPosition>,
        default: NotificationListPosition.TopRight
    }
});

// Access the notifications from the store
const { notifications } = useNotificationStore();

// Compute the notification list based on the position
const notificationList = computed<Record<string, Notification>>(() => {
    if (notificationListPosition.value.includes(NotificationListItemLayout.Bottom)) {
        return notifications;
    }

    return Object.keys(notifications.value)
        .reverse()
        .reduce((acc, cur) => ({ ...acc, [cur]: notifications.value[cur] }), {});
});

// Compute the notification list position based on the props
const notificationListPosition = computed(() => {
    const positions = Object.values(NotificationListPosition);
    return positions.includes(props.position) ? props.position : NotificationListPosition.BottomRight;
});

// Compute the CSS class for the notification list
const classList = computed(() => `notification-list--${notificationListPosition.value}`);

// Compute the layout for the notification list items
const notificationListItemLayout = computed(() => {
    return notificationListPosition.value.includes(NotificationListItemLayout.Left)
        ? NotificationListItemLayout.Left
        : NotificationListItemLayout.Right;
});
</script>
<template>
    <transition-group :class="classList" name="notification-list" tag="ul" appear class="notification-list fixed z-40">
        <vue-notification-list-item
            v-for="notification in notificationList"
            :key="notification.id"
            :notification="notification"
            :layout="notificationListItemLayout"
        />
    </transition-group>
</template>

<style lang="scss" scoped>
$timing-function: cubic-bezier(0.82, 0.085, 0.395, 0.895);

.notification-list {
    position: fixed;
    z-index: 999;
    margin: 0;
    padding: 0;

    &--top-left {
        top: 0;
        left: 0;

        .notification-list-enter-from {
            opacity: 0;
            transform: translateX(-100%);
        }

        .notification-list-leave-to {
            opacity: 0;
            transform: translate(-100%, calc(0% - px(8)));
        }

        .notification-list-enter-to,
        .notification-list-leave-from {
            opacity: 1;
        }

        .notification-list-enter-active,
        .notification-list-leave-active {
            transition: all 0.3s $timing-function;
        }

        .notification-list-leave-active {
            position: absolute;
        }

        .notification-list-move {
            transition: all 0.6s ease-in-out;
        }
    }

    &--bottom-left {
        bottom: 0;
        left: 0;

        .notification-list-enter-from {
            opacity: 0;
            transform: translateX(-100%);
        }

        .notification-list-leave-to {
            opacity: 0;
            transform: translate(-100%, calc(-100% - px(8)));
        }

        .notification-list-enter-to,
        .notification-list-leave-from {
            opacity: 1;
        }

        .notification-list-enter-active,
        .notification-list-leave-active {
            transition: all 0.3s $timing-function;
        }

        .notification-list-leave-active {
            position: absolute;
        }

        .notification-list-move {
            transition: all 0.6s ease-in-out;
        }
    }

    &--top-right {
        top: 0;
        right: 0;

        .notification-list-enter-from {
            opacity: 1;
            transform: translateX(100%);
        }

        .notification-list-leave-to {
            opacity: 0;
            transform: translate(100%, calc(0% - px(8)));
        }

        .notification-list-enter-to,
        .notification-list-leave-from {
            opacity: 1;
        }

        .notification-list-enter-active,
        .notification-list-leave-active {
            transition: all 0.3s $timing-function;
        }

        .notification-list-leave-active {
            position: absolute;
            z-index: 90;
        }

        .notification-list-move {
            transition: all 0.6s ease-in-out;
        }
    }

    &--bottom-right {
        bottom: 0;
        right: 0;

        .notification-list-enter-from {
            opacity: 0;
            transform: translateX(100%);
        }

        .notification-list-leave-to {
            opacity: 0;
            transform: translate(100%, calc(-100% - px(8)));
        }

        .notification-list-enter-to,
        .notification-list-leave-from {
            opacity: 1;
        }

        .notification-list-enter-active,
        .notification-list-leave-active {
            transition: all 0.3s $timing-function;
        }

        .notification-list-leave-active {
            position: absolute;
            z-index: 90;
        }

        .notification-list-move {
            transition: all 0.6s ease-in-out;
        }
    }
}
</style>
