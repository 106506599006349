import { computed, reactive } from 'vue';
import { type Notification } from './notification.interface';
import { defaultNotification } from './notification.factory';

interface NotificationStore {
    notifications: Record<string, Notification>;
    setNotification: (notification: Partial<Notification>) => void;
    unsetNotification: (idNotification: string) => void;
}

const state = reactive<NotificationStore>({
    notifications: {},
    setNotification(notification) {
        const fullNotification = {
            ...defaultNotification(),
            ...notification
        };

        state.notifications[fullNotification.id] = fullNotification;
    },
    unsetNotification(idNotification) {
        delete state.notifications[idNotification];
    }
});

export const useNotificationStore = () => {
    const notifications = computed(() => state.notifications);

    const notification = (id: string) => computed(() => state.notifications[id]);

    return {
        notifications,
        notification,
        setNotification: state.setNotification,
        unsetNotification: state.unsetNotification
    };
};
