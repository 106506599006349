export default () => {
    // В куках хранится токен
    const auth = useCookie('_token_auth', {
        maxAge: 60 * 60 * 24 * 30 // 30 days cookie expires
    });

    const packages = useState('packages', (): string[] => []);

    const accessToken = !auth.value ? undefined : auth.value;
    const isAuth = computed(() => !!auth.value);

    /**
     * Установка токена
     * @param token
     */
    const setToken = (token: string) => {
        auth.value = token;
    };

    /**
     * Проверяем валидный ли токен
     * @returns
     */
    const check = async () => {
        if (auth.value) {
            const { data, error } = await useFetchProxyApi<any, any>(`/api/v1/auth/check`, {
                method: 'POST',
                token: auth.value
            });

            if (error.value) {
                return reset();
            } else if (data.value?.user?.packages) {
                packages.value = data.value.user.packages;
            }
        }
    };

    const reset = () => {
        auth.value = undefined;
        return authError();
    };

    const authError = () => {
        return navigateTo('/auth');
    };

    return {
        packages: packages.value,
        accessToken,
        isAuth,
        check,
        reset,
        setToken,
        authError
    };
};
