<script setup lang="ts">
import { type PropType, ref, h, vShow } from 'vue';
import { type Orientation, type NodeConfig, type Node, type NodeIndexRange, type NodeIndex } from 'lrud';

const props = defineProps({
    /** Active focus */
    isFocusable: {
        type: Boolean,
        default: false
    },
    /** Node on which the focus will be at initialization */
    defaultFocus: {
        type: Boolean,
        default: false
    },
    orientation: {
        type: String as PropType<Orientation | undefined>,
        required: false,
        default: 'horizontal'
    },
    class: {
        type: [String, Object] as PropType<string | object>,
        required: false,
        default: ''
    },
    type: {
        type: String,
        required: false,
        default: 'div'
    },
    /** Index alignment ensures that when focus would move from one of this nodes descendants to another, LRUD will attempt to ensure that the focused node matches the index of the node that was left, e.g to make 2 lists behave as a "grid". */
    isIndexAlign: {
        type: Boolean,
        default: false
    },
    indexRange: {
        type: Object as PropType<NodeIndexRange>
    },
    lrudScroll: {
        type: Boolean,
        required: false,
        default: null
    },
    /** Hide the element and make fucus impossible */
    hide: {
        type: Boolean,
        default: false
    },
    shouldCancelLeave: {
        type: Function as PropType<() => boolean>,
        default: undefined
    },
    /** Default parent is real parent vue node */
    parent: {
        type: String,
        default: undefined
    },
    /** Index tree */
    index: {
        type: Number as PropType<NodeIndex>,
        default: undefined
    },
    isStopPropagate: {
        type: Boolean,
        default: undefined
    }
});

const classes = ref({
    lrud: true,
    lrud_focused: false
});

const index = ref<number | undefined>(props.index);

const instance = getCurrentInstance();
const generateUid = () => 'lrud-' + instance?.uid;

const uid = shallowRef();
const previouslyRegisteredNode = shallowRef(false);
const { $lrud } = useNuxtApp();

type MoveEvent = Parameters<NonNullable<Node['onMove']>>[0];
type activeChildChangeEvent = Parameters<NonNullable<Node['onActiveChildChange']>>[0];

const emit = defineEmits<{
    register: [node: Node];
    focused: [node: Node];
    selected: [node: Node];
    leave: [node: Node];
    move: [event: MoveEvent];
    active: [node: Node];
    activeChildChange: [event: activeChildChangeEvent];
}>();

const mountedLrud = () => {
    if (process.client) {
        const params: NodeConfig = {
            orientation: props.orientation,
            isFocusable: props.isFocusable && !props.hide ? true : undefined,
            isIndexAlign: props.isIndexAlign ? true : undefined,
            isStopPropagate: props.isStopPropagate ? true : undefined,
            indexRange: props.indexRange,
            index: index.value
        };

        /** Register node option */
        let option: any;
        if (props?.parent) {
            option = {
                parent: props?.parent
            };
        } else {
            option = {
                parent: instance?.parent ? instance?.parent : undefined
            };
        }

        // Регистрация ноды и проставление событий
        nextTick(async () => {
            await $lrud.register(uid.value, params, option).then((node) => {
                // Если фокус на элементе применен раньше регистрации компонента
                if ($lrud.base.currentFocusNode === node) {
                    classes.value.lrud_focused = true;
                }
                node.onLeave = () => {
                    emit('leave', node);
                };
                node.onActive = () => {
                    emit('active', node);
                    if (props.lrudScroll) {
                        document.getElementById(node.id)?.scrollIntoView({ block: 'center' });
                    }
                };
                node.onActiveChildChange = (event) => {
                    emit('activeChildChange', event);
                };
                node.onEnter = () => {
                    if (props.lrudScroll) {
                        document.getElementById(node.id)?.scrollIntoView({ block: 'center' });
                    }
                };
                node.onFocus = () => {
                    classes.value.lrud_focused = true;
                    emit('focused', node);
                };
                node.onSelect = () => {
                    emit('selected', node);
                };
                node.onMove = (event: MoveEvent) => {
                    emit('move', event);
                };
                node.onBlur = () => {
                    classes.value.lrud_focused = false;
                };
                node.shouldCancelLeave = props?.shouldCancelLeave || undefined;

                if (props.defaultFocus && !!node) {
                    $lrud.assignFocus(node);
                }
                // Следим за параметрами и меняем состояние фокусировки
                watch(
                    () => props.isFocusable,
                    (isFocusable) => {
                        $lrud.setNodeFocusable(node, isFocusable);
                    }
                );
                // Следим за параметрами и меняем состояние фокусировки
                watch(
                    () => props.hide,
                    (hide) => {
                        $lrud.setNodeFocusable(node, !hide);
                    }
                );
                if (node.index !== undefined) {
                    index.value = node.index;
                }
                emit('register', node);
                previouslyRegisteredNode.value = true;
            });
        });
    }
};

onBeforeMount(() => {
    uid.value = generateUid();
    mountedLrud();
});

onActivated(() => {
    // Если был раннее зарегистрирован
    if (previouslyRegisteredNode.value) {
        uid.value = generateUid();
        nextTick(() => mountedLrud());
    }
});

// При размонтировании удаляем из lrud
onBeforeUnmount(() => {
    if (uid.value) {
        $lrud.base.unregisterNode(uid.value, { forceRefocus: false });
    }
});

onDeactivated(() => {
    if (uid.value) {
        $lrud.base.unregisterNode(uid.value, { forceRefocus: false });
    }
});

const nodeElement = ref<HTMLElement>();
defineExpose({
    el: nodeElement
});

const slots = useSlots();
const render = () =>
    withDirectives(
        h(
            props.type,
            {
                id: uid.value,
                class: [classes.value, props.class],
                ref: nodeElement
            },
            slots.default ? slots.default() : undefined
        ),
        [[vShow, !props.hide]]
    );
</script>

<template>
    <render />
</template>
