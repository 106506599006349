export const appHead = {"meta":[{"name":"viewport","content":"user-scalable=no, initial-scale=1, width=device-width, viewport-fit=cover"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"src":"https://www.googletagmanager.com/gtag/js?id=G-HVJWDVPWQ1","async":true},{"innerHTML":"\n                        window.dataLayer = window.dataLayer || [];\n                        function gtag(){dataLayer.push(arguments);}\n                        gtag('js', new Date());\n                    \n                        gtag('config', 'G-HVJWDVPWQ1');\n                    "},{"innerHTML":"\n                        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n                            m[i].l=1*new Date();\n                            for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n                            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n                            (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n                            ym(97558109, \"init\", {\n                                clickmap:true,\n                                trackLinks:true,\n                                accurateTrackBounce:true,\n                                webvisor:true\n                            });\n                    "}],"noscript":[],"viewport":"user-scalable=no, initial-scale=1, width=device-width, viewport-fit=cover"}

export const appKeepalive = {"max":3,"exclude":["PageLive","PageItem"]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'