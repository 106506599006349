import { nextTick, type ComponentInternalInstance, ref } from 'vue';
import { Lrud, type NodeConfig, type Node, type NodeId } from 'lrud';
import { Directions } from './interfaces';
import { KeyCodes } from './key-codes';
import { LrudEmitter, type CallbackParameters } from './lrud-emitter';
// @ts-expect-error virtual file
import { appKeepalive } from '#build/nuxt.config.mjs';

type FocusHistory = {
    page: string;
    focus: string | undefined;
};

export default () => {
    // create an instance, register some nodes and assign a default focus
    const base = new Lrud();

    // Хранение истории состояния фокуса при переходах
    const focusHistoryState = shallowRef<FocusHistory[]>([]);
    function addFocusHistory(focusObject: FocusHistory) {
        const currentHistory = focusHistoryState.value;
        currentHistory.push(focusObject);
        // Учитываем сколько хранить в памяти
        if (currentHistory.length > appKeepalive.max) {
            currentHistory.splice(0, 1);
        }
        focusHistoryState.value = currentHistory;
    }

    // Список пользовательских колбэков
    const emitter = new LrudEmitter();
    // Стандартный прослушиватель для нажатия клавиши, если его не перекрыли нижестоящие
    emitter.on(({ keyboardEvent }) => {
        if (keyboardEvent) {
            base.handleKeyEvent(keyboardEvent);
        }
    });

    // Регистрация обработчиков событий
    if (process.client && document) {
        document.addEventListener('keydown', (event: KeyboardEvent) => {
            event.preventDefault();
            const callbackProperties = <CallbackParameters>{
                keyboardEvent: event,
                direction: event.keyCode ? KeyCodes[event.keyCode] : undefined
            };
            emitter.emit(callbackProperties);
        });
    }

    // Регистрация приложения как главного lrud блока
    const nuxtApp = useNuxtApp();
    nuxtApp.hook('app:mounted', (vueApp) => {
        if (vueApp._container?.id) {
            base.registerNode(vueApp._container.id, {
                orientation: undefined
            });
        }
    });

    const isLoaded = ref(false);
    // При каждой загрузке страницы объявляем загрузку
    nuxtApp.hook('page:loading:start', () => {
        // Пишем в историю какой был фокус
        if (base.currentFocusNode?.id) {
            const route = useRoute();
            // Записываем в историю, если не находится в исключениях
            const routePageName = route.matched?.[0].components?.default?.name;
            if (appKeepalive.exclude && (!routePageName || !appKeepalive.exclude.includes(routePageName))) {
                addFocusHistory({
                    page: route.path,
                    focus: base.currentFocusNode.id
                });
            }
        }
        isLoaded.value = false;
    });

    // При переходах в keep alive
    base.emitter.on('lrud:register', (node) => {
        if (node.id) {
            const route = useRoute();
            // Если фокус был на этом элементе
            const isPrevFocus = focusHistoryState.value.find(
                (item) => item.page === route.path && item.focus === node.id
            );
            if (isPrevFocus) {
                // Ждем пока все прогрузится (фокус могут сбить defaultFocus от lrudnode)
                setTimeout(() => {
                    base.assignFocus(node);
                }, 50);
            }
        }
    });

    // Подгружаем ноды, которые загрузились раньше хука прогрузки страницы
    nuxtApp.hook('page:loading:end', () => {
        isLoaded.value = true;
        nextTick(() => {
            base.emitter.emit('page:loaded');
        });
    });

    nuxtApp.hook('app:error', () => {
        isLoaded.value = true;
        nextTick(() => {
            base.emitter.emit('page:loaded');
        });
    });

    // Обрабатываем nodeConfig
    const prepareNodeConfig = (nodeConfig: NodeConfig, options?: any): NodeConfig => {
        // Получаем родительский компонент, если не указан предок
        if (options?.parent && !nodeConfig?.parent) {
            let parent: ComponentInternalInstance | string | null = options?.parent;
            if (typeof parent !== 'string') {
                while (parent && !nodeConfig?.parent) {
                    nodeConfig.parent = parent?.type.__name === 'LrudNode' ? parent.vnode.el?.id : undefined;
                    parent = parent.parent;
                }
            }
        }
        // Если предка нет, то это будет app
        if (!nodeConfig.parent) {
            nodeConfig.parent = nuxtApp.vueApp?._container?.id;
        }
        return nodeConfig;
    };

    // Регистрация lrud нод
    const register = (node: NodeId, nodeConfig: NodeConfig, options: any): Promise<Node> => {
        return new Promise((resolve, reject) => {
            // Если страница еще не прогрузилась, делаем отложенный запуск
            if (!isLoaded.value) {
                const lazyRegister = () => {
                    base.emitter.off('page:loaded', lazyRegister);
                    resolve(register(node, nodeConfig, options));
                };
                base.emitter.on('page:loaded', lazyRegister);
                return;
            }

            nextTick(() => {
                const nodeConfigFormat = prepareNodeConfig(nodeConfig, options);
                // Если есть родитель и он зарегистрирован
                if (nodeConfigFormat.parent && !!base.getNode(nodeConfigFormat.parent)) {
                    // // Если вручную проставлен индекс (для onActivated восстановления)
                    if (options.awaitSiblings && nodeConfigFormat.index !== undefined && nodeConfigFormat.index > 0) {
                        const parent = base.getNode(nodeConfigFormat.parent);

                        // Если у родителя меньше дочерних элементов, чем нужный индекс
                        if (parent && (!parent.children || nodeConfigFormat.index > parent.children?.length)) {
                            const awaitSiblings = (siblingNodeAdd: Node) => {
                                if (
                                    nodeConfigFormat.parent === siblingNodeAdd.parent?.id &&
                                    siblingNodeAdd.index !== undefined &&
                                    siblingNodeAdd.index + 1 === nodeConfigFormat.index
                                ) {
                                    resolve(register(node, nodeConfig, options));
                                    base.emitter.off('lrud:register', awaitSiblings);
                                }
                            };
                            // Ожидаем регистрации соседа с предыдущим индексом
                            base.emitter.on('lrud:register', awaitSiblings);
                            return;
                        }
                    }

                    // Если этой ноды нет в древе, регистрируем
                    if (!base.getNode(node)) {
                        base.registerNode(node, nodeConfigFormat);
                    }
                    const nodeItem = base.getNode(node);
                    if (nodeItem) {
                        base.emitter.emit('lrud:register', nodeItem);
                        return resolve(nodeItem);
                    } else {
                        return reject(new Error('Error: register lrud node'));
                    }
                } else if (nodeConfigFormat.parent && !base.getNode(nodeConfigFormat.parent)) {
                    const awaitParent = (awaitParentNode: Node) => {
                        if (awaitParentNode.id === nodeConfig.parent) {
                            options.awaitSiblings = true;
                            resolve(register(node, nodeConfig, options));
                            base.emitter.off('lrud:register', awaitParent);
                        }
                    };
                    base.emitter.on('lrud:register', awaitParent);
                    return;
                }

                // Такое может произойти, если до момента регистрации компонент пропал (реактивность)
                reject(new Error('Error: node not found'));
            });
        });
    };

    // Установка, возможен ли фокус на элементе
    const setNodeFocusable = (node: NodeId | Node, isFocusable: boolean): void => {
        base.setNodeFocusable(node, isFocusable);
    };

    // Фокус на элемент
    const assignFocus = (node: NodeId | Node) => {
        const realNode = typeof node === 'string' ? base.getNode(node) : node;
        if (!realNode) {
            return;
        }
        if (!realNode.isFocusable) {
            const realNodeFocus = base.digDown(realNode, Directions.UNSPECIFIED);
            if (realNodeFocus) {
                assignFocus(realNodeFocus);
            } else {
                // Отложенный фокус на элементе, до его прогрузки
                const awaitNode = (nodeRegister: Node) => {
                    const realNodeFocus = base.digDown(realNode, Directions.UNSPECIFIED);
                    if (nodeRegister === realNodeFocus) {
                        assignFocus(realNodeFocus);
                        base.emitter.off('lrud:register', awaitNode);
                    }
                };
                base.emitter.on('lrud:register', awaitNode);
            }
        } else {
            base.assignFocus(realNode);
        }
    };

    return {
        base,
        register,
        setNodeFocusable,
        assignFocus,
        emitter
    };
};
