<script setup lang="ts">
const props = defineProps({
    error: Object
});

const statusCode = computed(() => {
    return props.error?.statusCode;
});

const statusMessage = computed(() => {
    return props.error?.statusMessage;
});
</script>

<template>
    <div class="wrapper">
        <Aside />
        <main class="main main_middle">
            <div class="main__content">
                <div class="text-center">
                    <h1 v-if="statusCode == 404" class="title fs-56 fs_m-40">Страница не найдена: {{ statusCode }}</h1>
                    <div v-else>
                        <h1 class="title fs-56 fs_m-40">Ошибка: {{ statusMessage }}</h1>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <vue-notification-list />
</template>

<style lang="scss" scoped>
.error_log {
    font-size: 1.3em;
}
</style>
