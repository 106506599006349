export enum Directions {
    LEFT = 'left',
    RIGHT = 'right',
    UP = 'up',
    DOWN = 'down',
    ENTER = 'enter',
    BACK = 'back',
    UNSPECIFIED = '*'
}

export type Direction = `${Directions}`;
