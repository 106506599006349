import type { NotificationItemType } from '~/modules/notification/runtime/composables/useNotify';

// Тип для кода ошибки
type ErrorCodeType = {
    readonly code: number;
    readonly msg: string;
    readonly notify: NotificationItemType;
};

export const LoggerCode = {
    NOT_FOUND: {
        code: 40004,
        msg: 'Данные на сервере не найдены',
        notify: `alert`
    } as ErrorCodeType,
    USE_FETCH_UNKNOWN_ERROR: {
        code: 20001,
        msg: 'Ошибка получения данных с сервера',
        notify: `alert`
    } as ErrorCodeType,
    USE_FETCH_SERVER_ERROR: {
        code: 20005,
        msg: 'Internal Server Error',
        notify: `alert`
    } as ErrorCodeType,
    NOT_FOUND_CLOSE_APP_ACTION: {
        code: 20006,
        msg: 'Приложение не поддерживает данное действие',
        notify: `warning`
    },
    HLS_NETWORK_ERROR: {
        code: 30001,
        msg: 'Сетевая ошибка: Проблема с сетью',
        notify: `alert`
    } as ErrorCodeType,
    HLS_MEDIA_ERROR: {
        code: 30002,
        msg: 'Ошибка медиа: Проблема с медиа/видео',
        notify: `alert`
    } as ErrorCodeType,
    HLS_KEY_SYSTEM_ERROR: {
        code: 30003,
        msg: 'Ошибка ключевой системы: Проблема с EME',
        notify: `alert`
    } as ErrorCodeType,
    HLS_MUX_ERROR: {
        code: 30004,
        msg: 'Проблемы с демультиплексированием/ремультиплексированием',
        notify: `alert`
    } as ErrorCodeType,
    HLS_OTHER_ERROR: {
        code: 30004,
        msg: 'Неопределенная проблема. Обратитесь в нашу техподдержку для помощи',
        notify: `alert`
    } as ErrorCodeType,
    AUTH_ERROR: {
        code: 40003,
        msg: 'Ошибка авторизации',
        notify: `alert`
    } as ErrorCodeType,
    STREAM_FETCH: {
        code: 40005,
        msg: 'Ошибка получения видеопотока',
        notify: `alert`
    } as ErrorCodeType,
    COMPILATION_FILTER_NOT_FOUND_SECTION: {
        code: 40006,
        msg: 'Данной подборки не существует',
        notify: `warning`
    } as ErrorCodeType
} as const;

type LoggerCodeKeys = keyof typeof LoggerCode;

// Функция для создания лога ошибки
export function useLogger(loggerCode: LoggerCodeKeys, ...data: any) {
    const error = LoggerCode[loggerCode];
    // eslint-disable-next-line
    console.debug(error, ...data);
    useNotify(`${error.code}: ${error.msg}`, error.notify, {
        id: error.code // Ограничиваем выводом одной нотификации, если ошибок несколько
    });
}
