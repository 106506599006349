import * as Sentry from '@sentry/vue';
import type { Router } from 'vue-router';

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const { vueApp } = nuxtApp;
    Sentry.init({
        enabled: process.env.NODE_ENV !== 'development',
        app: [vueApp],
        dsn: config.public.SENTRY_DSN,
        autoSessionTracking: true,
        debug: !!config.public.SENTRY_DEBUG,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router as Router),
                tracePropagationTargets: [config.public.apiProxy, config.public.apiBase, /^\//]
            }),
            new Sentry.Replay()
        ],
        tracesSampleRate: 1.0, // Sentry recommends adjusting this value in production
        replaysOnErrorSampleRate: 1.0,
        environment: process.env.NODE_ENV // Set environment
    });

    vueApp.mixin(
        Sentry.createTracingMixins({
            trackComponents: true,
            timeout: 2000,
            hooks: ['activate', 'create', 'destroy', 'mount', 'update']
        })
    );

    if (process.env.NODE_ENV !== 'development') {
        Sentry.attachErrorHandler(vueApp, {
            logErrors: true,
            attachProps: true,
            trackComponents: true,
            timeout: 2000,
            hooks: ['activate', 'create', 'destroy', 'mount', 'update']
        });
    }

    return {
        provide: {
            sentrySetContext: Sentry.setContext,
            sentrySetUser: Sentry.setUser,
            sentrySetTag: Sentry.setTag,
            sentryAddBreadcrumb: Sentry.addBreadcrumb,
            sentryCaptureException: Sentry.captureException
        }
    };
});
