<script setup lang="ts">
import { computed, onMounted, type PropType, ref } from 'vue';
import { type Notification } from '../notification.interface';
import {
    NotificationListItemAppearance,
    NotificationListItemLayout,
    NotificationListItemType
} from '../notification.enum';
import { useNotificationStore } from '../notification.store';
import { ICONS } from '../notification.constant';

const props = defineProps({
    notification: {
        type: Object as PropType<Notification>,
        required: true
    },
    layout: {
        type: String as PropType<NotificationListItemLayout>,
        default: NotificationListItemLayout.Left
    }
});

const tagRef = ref<HTMLElement>();

const { unsetNotification } = useNotificationStore();

const notificationListItemType = computed<NotificationListItemType>(() =>
    Object.values(NotificationListItemType).includes(props.notification.type)
        ? props.notification.type
        : NotificationListItemType.Info
);

const notificationListItemLayout = computed<NotificationListItemLayout>(() =>
    Object.values(NotificationListItemLayout).includes(props.layout) ? props.layout : NotificationListItemLayout.Left
);

const notificationListItemAppearance = computed<NotificationListItemAppearance>(() =>
    Object.values(NotificationListItemAppearance).includes(props.notification.appearance)
        ? props.notification.appearance
        : NotificationListItemAppearance.Light
);

const classList = computed(() => ({
    'notification-list-item--dismissible-manual': props.notification.dismiss.manually,
    'notification-list-item--dismissible-automatic':
        props.notification.dismiss.automatically && props.notification.showDurationProgress,
    [`notification-list-item--${notificationListItemType.value}`]: true,
    [`notification-list-item--${notificationListItemLayout.value}`]: true,
    [`notification-list-item--${notificationListItemAppearance.value}`]: true
}));

const animationDurationStyle = ref({
    // duration - appear time - start delay - end delay
    animationDuration: `${props.notification.duration - 500 - 100}ms`
});

const dismissManually = () => {
    if (!props.notification.dismiss.manually) return;

    unsetNotification(props.notification.id);
};

const dismissAutomatically = () => {
    if (!props.notification.dismiss.automatically) return;

    setTimeout(() => {
        unsetNotification(props.notification.id);
    }, props.notification.duration);
};

onMounted(() => {
    dismissAutomatically();
});
</script>

<template>
    <li :class="classList" class="notification-list-item" @click="dismissManually">
        <div ref="tagRef" :style="animationDurationStyle" class="notification-list-item__tag" />
        <div class="notification-list-item__message">
            <span v-html="notification.message" />
        </div>
        <div class="notification-list-item__icon">
            <span v-if="notification.showIcon" v-html="ICONS[notificationListItemType]" />
        </div>
    </li>
</template>

<style lang="scss" scoped>
$color-success: #009800;
$color-alert: #ff3600;
$color-warning: #ffa600;
$color-info: #0088ff;

.notification-list-item {
    $root: &;

    position: relative;
    display: flex;
    margin: px(8);
    min-height: px(74);
    width: 100vw;
    max-width: px(400);
    border-radius: px(4);
    overflow: hidden;

    &--light {
        background-color: #fcfcfc;
        border: px(1) solid #eee;
        color: #333;
    }

    &--dark {
        background-color: #333;
        border: px(1) solid #222;
        color: #eee;
    }

    &--glass {
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(px(5));
        border: px(1) solid rgba(255, 255, 255, 0.125);
        border-top-color: rgba(255, 255, 255, 0.25);
        border-left-color: rgba(255, 255, 255, 0.25);
        color: #eee;
    }

    &--dismissible-manual {
        cursor: pointer;
    }

    &__tag {
        position: absolute;
        bottom: 0;
        height: 100%;
        width: px(6);

        #{$root}--dismissible-automatic & {
            animation-name: progress;
            animation-timing-function: linear;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
        }
    }

    &__message {
        z-index: 1;
        flex: 1;
        padding: px(12) px(12) px(12) px(18);
    }

    &__icon {
        position: absolute;
        bottom: calc(px(24) * -1);
        opacity: 0.25;
        width: px(80);

        #{$root}--glass & {
            opacity: 0.5;
        }
    }

    &--left {
        text-align: right;

        #{$root}__tag {
            right: 0;
        }

        #{$root}__icon {
            left: calc(px(24) * -1);
            transform: rotate(12deg);
        }
    }

    &--right {
        text-align: left;

        #{$root}__icon {
            right: calc(px(24) * -1);
            transform: rotate(-12deg);
        }
    }

    &--success {
        #{$root}__tag {
            background-color: $color-success;
        }

        #{$root}__icon {
            color: $color-success;
        }
    }

    &--alert {
        #{$root}__tag {
            background-color: $color-alert;
        }

        #{$root}__icon {
            color: $color-alert;
        }
    }

    &--warning {
        #{$root}__tag {
            background-color: $color-warning;
        }

        #{$root}__icon {
            color: $color-warning;
        }
    }

    &--info {
        #{$root}__tag {
            background-color: $color-info;
        }

        #{$root}__icon {
            color: $color-info;
        }
    }
}
</style>

<style lang="scss">
@keyframes progress {
    0% {
        height: 100%;
        opacity: 1;
    }
    100% {
        height: 0;
        opacity: 0.5;
    }
}
</style>
