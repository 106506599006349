import { Directions, type Direction } from './interfaces';

export const KeyCodes: { [keyCode: number]: Direction } = {
    4: Directions.LEFT,
    21: Directions.LEFT,
    37: Directions.LEFT,
    214: Directions.LEFT,
    205: Directions.LEFT,
    218: Directions.LEFT,
    5: Directions.RIGHT,
    22: Directions.RIGHT,
    39: Directions.RIGHT,
    213: Directions.RIGHT,
    206: Directions.RIGHT,
    217: Directions.RIGHT,
    29460: Directions.UP,
    19: Directions.UP,
    38: Directions.UP,
    211: Directions.UP,
    203: Directions.UP,
    215: Directions.UP,
    29461: Directions.DOWN,
    20: Directions.DOWN,
    40: Directions.DOWN,
    212: Directions.DOWN,
    204: Directions.DOWN,
    216: Directions.DOWN,
    29443: Directions.ENTER,
    13: Directions.ENTER,
    67: Directions.ENTER,
    32: Directions.ENTER,
    23: Directions.ENTER,
    195: Directions.ENTER,
    27: Directions.BACK // PC "esc" button
};
