// useFetchApi(url, opts)
// Обертка над useFetch с возможностью отправлять объекты в query и базовым url API
// ---------------------------
import type { UseFetchOptions } from 'nuxt/app';

interface SelectObject {
    [key: string]: string | SelectObject;
}

// Функция которая парсит select массив
const parseSelect = (selectArray: string[]): SelectObject => {
    const result: SelectObject = {};
    selectArray.forEach((item) => {
        const keys = item.split('.');
        let currentObj: SelectObject = result;

        keys.forEach((key, index) => {
            if (index === keys.length - 1) {
                currentObj[key] = '*';
            } else {
                currentObj[key] = currentObj[key] || {};
                currentObj = currentObj[key] as SelectObject;
            }
        });
    });

    return result;
};

// Функция для преобразования объекта в строку параметров URL
const stringify = (obj: any, prefix?: string): string => {
    const pairs = [];
    for (const key in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
            continue;
        }
        const value = obj[key];
        const enkey = encodeURIComponent(key);
        let pair;
        if (typeof value === 'object') {
            pair = stringify(value, prefix ? `${prefix}[${enkey}]` : enkey);
        } else {
            pair = `${prefix ? `${prefix}[${enkey}]` : enkey}=${encodeURIComponent(value)}`;
        }
        pairs.push(pair);
    }
    return pairs.join('&');
};

// Тип, представляющий параметры функции useFetch
type UseFetchParameters = Parameters<typeof useFetch>;

// Тип Request
export type UseFetchRequest = UseFetchParameters[0];

// Дополним своим параметром в опциях
export type UseFetchOptionsCustom<Type, Key> = UseFetchOptions<Type, Key> & { token?: string };

export default function <Type, Key>(request: UseFetchRequest, opts?: UseFetchOptionsCustom<Type, Key>) {
    // Получаем конфигурацию из файла nuxt.config.ts
    const config = useRuntimeConfig();
    const protocol = 'https:';

    // Если параметры opts не переданы, создаем пустой объект
    if (!opts) {
        opts = {};
    }

    // Если заголовки не указаны, создаем пустой объект
    if (!opts?.headers) {
        opts.headers = {} as Record<string, string>;
    }

    // Токен авторизации
    if (opts?.token && opts?.headers) {
        (opts.headers as Record<string, string>).Authorization = `Bearer ${opts.token}`;
        delete opts.token;
    }

    // Отправляем запрос, используя функцию useFetch
    const responseFetch = useFetch(request, {
        // Формируем базовый URL для API, объединяя протокол, хост и путь из конфигурации
        baseURL: protocol + '//' + config.public.apiBase, // <<<==== CONFIG (nuxt.config.ts) PARAM BASE URL

        // Разрешаем прокидывание данных авторизации в запросах
        // credentials: 'include',

        // Функция, которая будет вызвана перед отправкой запроса (перехватчик запроса)
        onRequest(context) {
            // Парсим select из массива в объект
            if (!!context.options?.params?.select && Array.isArray(context.options.params.select)) {
                const parsedSelect: SelectObject = parseSelect(context.options.params.select);
                context.options.params.select = parsedSelect;
            }
            // Если опция method указана в параметрах и равна 'POST', то обрабатываем запрос как POST-запрос
            if (opts?.method && opts.method === 'POST') {
                // Создаем новый объект Headers на основе переданных заголовков
                context.options.headers = new Headers(context.options.headers);
                // Устанавливаем заголовок 'Content-Type' для указания типа данных в запросе
                context.options.headers.set('Content-Type', 'application/x-www-form-urlencoded');
                // Преобразуем объект тела запроса в строку параметров URL и устанавливаем в свойство body
                context.options.body = stringify(context?.options?.body);
            } else {
                // Обрабатываем запрос как GET-запрос или другой тип, кроме POST
                // Преобразуем объект параметров запроса в строку параметров URL и обновляем свойство params
                const paramsConverted = new URLSearchParams(stringify(context?.options?.params));
                context.options.params = Object.fromEntries(paramsConverted);
            }
        },
        ...opts
    });

    // After response middleware (error handling)
    responseFetch.then((response) => {
        if (response.error && response.error.value) {
            const responseError = response.error.value;
            const errorDatas = responseError?.data;
            // Bad packeges or tarrifs (1 - package 2 - tarrifs)
            if ([1, 2].includes(errorDatas?.data.code) && errorDatas?.data?.status === 400) {
                throw showError({
                    statusCode: errorDatas?.data?.status,
                    statusMessage: errorDatas?.data?.message
                });
            }
            if (responseError && request !== '/api/v1/auth/check') {
                switch (responseError.statusCode) {
                    case 401:
                        useLogger('AUTH_ERROR');
                        break;
                    case 500:
                        useLogger('USE_FETCH_SERVER_ERROR');
                        break;
                    default:
                        useLogger('USE_FETCH_UNKNOWN_ERROR');
                        break;
                }
            }
        }
    });

    return responseFetch;
}
