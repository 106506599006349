import Lrud from './lrud.js';

export default defineNuxtPlugin((nuxtApp) => {
    /**
     * Lrud class
     *
     * @example const { $lrud } = useNuxtApp();
     */
    nuxtApp.provide('lrud', Lrud());
});

declare module '#app' {
    interface NuxtApp {
        $lrud: ReturnType<typeof Lrud>;
    }
}

declare module 'nuxt/dist/app/nuxt' {
    interface NuxtApp {
        $lrud: ReturnType<typeof Lrud>;
    }
}
