import { default as authRP9a8I73nYMeta } from "/build/src/pages/auth.vue?macro=true";
import { default as indexcJhjWh0FBlMeta } from "/build/src/pages/browsing/index.vue?macro=true";
import { default as indexH1UvBtw9OnMeta } from "/build/src/pages/cinema/archive/[channelId]/index.vue?macro=true";
import { default as indexlhM1z3Zha4Meta } from "/build/src/pages/cinema/category/[categoryId]/index.vue?macro=true";
import { default as indexRwsNqIpEvdMeta } from "/build/src/pages/cinema/index.vue?macro=true";
import { default as _91id_93VqF48xbkxHMeta } from "/build/src/pages/container/[id].vue?macro=true";
import { default as indexLWezZaBHjOMeta } from "/build/src/pages/favorite/index.vue?macro=true";
import { default as index0k0X4FOHtyMeta } from "/build/src/pages/history/index.vue?macro=true";
import { default as indexcZEypTl7HWMeta } from "/build/src/pages/index.vue?macro=true";
import { default as indexdsT1te5iCNMeta } from "/build/src/pages/item/[id]/detail/index.vue?macro=true";
import { default as indexRl9x53ZP6VMeta } from "/build/src/pages/item/[id]/index.vue?macro=true";
import { default as indexj51VfSTxxDMeta } from "/build/src/pages/live/index.vue?macro=true";
import { default as indexqCU5OXrtD2Meta } from "/build/src/pages/search/index.vue?macro=true";
export default [
  {
    name: authRP9a8I73nYMeta?.name ?? "auth",
    path: authRP9a8I73nYMeta?.path ?? "/auth",
    meta: authRP9a8I73nYMeta || {},
    alias: authRP9a8I73nYMeta?.alias || [],
    redirect: authRP9a8I73nYMeta?.redirect || undefined,
    component: () => import("/build/src/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: indexcJhjWh0FBlMeta?.name ?? "browsing",
    path: indexcJhjWh0FBlMeta?.path ?? "/browsing",
    meta: indexcJhjWh0FBlMeta || {},
    alias: indexcJhjWh0FBlMeta?.alias || [],
    redirect: indexcJhjWh0FBlMeta?.redirect || undefined,
    component: () => import("/build/src/pages/browsing/index.vue").then(m => m.default || m)
  },
  {
    name: indexH1UvBtw9OnMeta?.name ?? "cinema-archive-channelId",
    path: indexH1UvBtw9OnMeta?.path ?? "/cinema/archive/:channelId()",
    meta: indexH1UvBtw9OnMeta || {},
    alias: indexH1UvBtw9OnMeta?.alias || [],
    redirect: indexH1UvBtw9OnMeta?.redirect || undefined,
    component: () => import("/build/src/pages/cinema/archive/[channelId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexlhM1z3Zha4Meta?.name ?? "cinema-category-categoryId",
    path: indexlhM1z3Zha4Meta?.path ?? "/cinema/category/:categoryId()",
    meta: indexlhM1z3Zha4Meta || {},
    alias: indexlhM1z3Zha4Meta?.alias || [],
    redirect: indexlhM1z3Zha4Meta?.redirect || undefined,
    component: () => import("/build/src/pages/cinema/category/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRwsNqIpEvdMeta?.name ?? "cinema",
    path: indexRwsNqIpEvdMeta?.path ?? "/cinema",
    meta: indexRwsNqIpEvdMeta || {},
    alias: indexRwsNqIpEvdMeta?.alias || [],
    redirect: indexRwsNqIpEvdMeta?.redirect || undefined,
    component: () => import("/build/src/pages/cinema/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VqF48xbkxHMeta?.name ?? "container-id",
    path: _91id_93VqF48xbkxHMeta?.path ?? "/container/:id()",
    meta: _91id_93VqF48xbkxHMeta || {},
    alias: _91id_93VqF48xbkxHMeta?.alias || [],
    redirect: _91id_93VqF48xbkxHMeta?.redirect || undefined,
    component: () => import("/build/src/pages/container/[id].vue").then(m => m.default || m)
  },
  {
    name: indexLWezZaBHjOMeta?.name ?? "favorite",
    path: indexLWezZaBHjOMeta?.path ?? "/favorite",
    meta: indexLWezZaBHjOMeta || {},
    alias: indexLWezZaBHjOMeta?.alias || [],
    redirect: indexLWezZaBHjOMeta?.redirect || undefined,
    component: () => import("/build/src/pages/favorite/index.vue").then(m => m.default || m)
  },
  {
    name: index0k0X4FOHtyMeta?.name ?? "history",
    path: index0k0X4FOHtyMeta?.path ?? "/history",
    meta: index0k0X4FOHtyMeta || {},
    alias: index0k0X4FOHtyMeta?.alias || [],
    redirect: index0k0X4FOHtyMeta?.redirect || undefined,
    component: () => import("/build/src/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: indexcZEypTl7HWMeta?.name ?? "index",
    path: indexcZEypTl7HWMeta?.path ?? "/",
    meta: indexcZEypTl7HWMeta || {},
    alias: indexcZEypTl7HWMeta?.alias || [],
    redirect: indexcZEypTl7HWMeta?.redirect || undefined,
    component: () => import("/build/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexdsT1te5iCNMeta?.name ?? "item-id-detail",
    path: indexdsT1te5iCNMeta?.path ?? "/item/:id()/detail",
    meta: indexdsT1te5iCNMeta || {},
    alias: indexdsT1te5iCNMeta?.alias || [],
    redirect: indexdsT1te5iCNMeta?.redirect || undefined,
    component: () => import("/build/src/pages/item/[id]/detail/index.vue").then(m => m.default || m)
  },
  {
    name: indexRl9x53ZP6VMeta?.name ?? "item-id",
    path: indexRl9x53ZP6VMeta?.path ?? "/item/:id()",
    meta: indexRl9x53ZP6VMeta || {},
    alias: indexRl9x53ZP6VMeta?.alias || [],
    redirect: indexRl9x53ZP6VMeta?.redirect || undefined,
    component: () => import("/build/src/pages/item/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexj51VfSTxxDMeta?.name ?? "live",
    path: indexj51VfSTxxDMeta?.path ?? "/live",
    meta: indexj51VfSTxxDMeta || {},
    alias: indexj51VfSTxxDMeta?.alias || [],
    redirect: indexj51VfSTxxDMeta?.redirect || undefined,
    component: () => import("/build/src/pages/live/index.vue").then(m => m.default || m)
  },
  {
    name: indexqCU5OXrtD2Meta?.name ?? "search",
    path: indexqCU5OXrtD2Meta?.path ?? "/search",
    meta: indexqCU5OXrtD2Meta || {},
    alias: indexqCU5OXrtD2Meta?.alias || [],
    redirect: indexqCU5OXrtD2Meta?.redirect || undefined,
    component: () => import("/build/src/pages/search/index.vue").then(m => m.default || m)
  }
]