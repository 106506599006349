// Импортируем функцию useNotify из модуля #imports и класс App из '@capacitor/app'.
import { App } from '@capacitor/app';
import type { CallbackParameters } from '~/modules/lrud/runtime/lrud-emitter';
import { navigationItems } from '@/components/Aside/navigation-links';

export default defineNuxtPlugin(() => {
    /**
     * Back button event handler
     */
    const { $lrud } = useNuxtApp();
    const emitter = $lrud.emitter;

    // Custom event params
    const eventParameters: CallbackParameters = {
        keyboardEvent: undefined,
        direction: 'back'
    };

    // Yes app back button
    document.addEventListener('native:backPressed', (e) => {
        e.preventDefault();
        emitter.emit(eventParameters);
    });

    // Capacitor back button
    App.addListener('backButton', () => {
        emitter.emit(eventParameters);
    });

    // Handler back button
    const asideLrudNode = () => useState('asideLrudNode', () => ref<any>());
    emitter.on(({ direction }) => {
        if (direction === 'back') {
            const route = useRoute();
            // Открываем меню
            const links = navigationItems.map((item) => item.link);
            if (links.includes(route.path) && route.path !== '/live') {
                const asideLrudNodeId = asideLrudNode().value?.el?.id;
                if (asideLrudNodeId) {
                    $lrud.assignFocus(asideLrudNodeId);
                    return;
                }
            }
            const router = useRouter();
            router.back();
        }
    });
});
